import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layouts";
import Seo from "../components/seo";
import { Section, SectionPageTitle, SectionDescription} from "../components/Section"

const ThankyouPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
      <Seo title="Thank You RTO Finanace" description="Thank You RTO Finanace" />
      <Section>
        <div className="container">
          <SectionPageTitle>Thank you</SectionPageTitle>
          <SectionDescription>Thank you for requesting a quote. Our team will get in touch with you soon.</SectionDescription>
        </div>
      </Section>
    </Layout>
  );
};

export default ThankyouPage;